import { CircularProgress, Container, Grid, Paper } from "@mui/material";
import {
  Apartment,
  Category,
  Chat,
  LocalOffer,
  Map,
  StarRounded,
  Upload,
  PictureInPicture,
  Check,
  DesignServices,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import StyledButton from "../styled/StyledButton";
import axios from "axios";
import CardSection from "../components/common/CardSection";
import { useCallback, useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";

const { REACT_APP_GATSBY_CLOUD_BUILD_WEBHOOK, REACT_APP_WEBSOCKET_URL } =
  process.env;

const Web = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const { lastMessage, readyState } = useWebSocket("ws://localhost:8085/ws");

  useEffect(() => {
    if (lastMessage !== null) {
      if (lastMessage?.data === "success") {
        setSuccess(true);
        setTimeout(() => {
          setLoading(false);
          setSuccess(false);
        }, 4000);
      }
    }
  }, [lastMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  useEffect(() => {
    console.log("connectionStatus ", connectionStatus);
  }, [connectionStatus]);

  const publishChanges = async () => {
    setLoading(true);
    if (REACT_APP_GATSBY_CLOUD_BUILD_WEBHOOK) {
      await axios.post(REACT_APP_GATSBY_CLOUD_BUILD_WEBHOOK);
    }
  };

  return (
    <>
      <Paper sx={{ pt: 12 }} elevation={0}>
        <Container>
          <Grid container>
            <Grid item>
              <StyledButton
                onClick={publishChanges}
                variant="contained"
                disabled={loading}
                startIcon={
                  loading ? (
                    success ? (
                      <Check />
                    ) : (
                      <CircularProgress size={12} sx={{ color: "white" }} />
                    )
                  ) : (
                    <Upload />
                  )
                }
              >
                {loading
                  ? success
                    ? "Cambios publicados correctamente"
                    : "Publicando cambios..."
                  : "Publicar cambios"}
              </StyledButton>
            </Grid>
          </Grid>
          <Grid
            justifyContent="space-between"
            sx={{ mt: 2 }}
            spacing={2}
            container
          >
            <Grid xs={12} md={3} item>
              <StyledButton
                color="primary"
                variant="outlined"
                startIcon={<Category />}
                onClick={() => navigate("/dashboard/categories")}
                fullWidth
              >
                Administrar categorías
              </StyledButton>
            </Grid>
            <Grid xs={12} md={3} item>
              <StyledButton
                color="primary"
                variant="outlined"
                startIcon={<LocalOffer />}
                onClick={() => navigate("/dashboard/offers")}
                fullWidth
              >
                Administrar ofertas
              </StyledButton>
            </Grid>
            <Grid xs={12} md={3} item>
              <StyledButton
                color="primary"
                variant="outlined"
                startIcon={<PictureInPicture />}
                onClick={() => navigate("/dashboard/banners")}
                fullWidth
              >
                Administrar banners
              </StyledButton>
            </Grid>
            <Grid xs={12} md={3} item>
              <StyledButton
                color="primary"
                variant="outlined"
                startIcon={<StarRounded />}
                onClick={() => navigate("/dashboard/features")}
                fullWidth
              >
                Administrar características
              </StyledButton>
            </Grid>
          </Grid>
          <Grid sx={{ mt: 2 }} spacing={2} container>
            <Grid xs={12} md={4} item>
              <CardSection
                icon={<Apartment sx={{ fontSize: 30 }} />}
                redirectTo="/dashboard/properties"
                title="Propiedades"
                actionButtonText="Administrar propiedades"
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <CardSection
                icon={<Map sx={{ fontSize: 30 }} />}
                redirectTo="/dashboard/locations"
                title="Ubicaciones"
                actionButtonText="Administrar ubicaciones"
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <CardSection
                icon={<Chat sx={{ fontSize: 30 }} />}
                redirectTo="/dashboard/testimonials"
                title="Testimonios"
                actionButtonText="Administrar testimonios"
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <CardSection
                icon={<DesignServices sx={{ fontSize: 30 }} />}
                redirectTo="/web/servicios"
                title="Servicios"
                actionButtonText="Administrar servicios"
              />
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </>
  );
};

export default Web;
